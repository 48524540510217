/**/
body {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.App {
  text-align: center;
}

.debug
{
  background-color: #ffffff;
}

.loginGroup
{
  background-color: #ffffff;
  margin: 0px auto 0px auto;
  padding: 10px 10px 10px 10px;
  border-radius: 18px;
  width: 398px;
  color: #ffffff;
}

.loginGroup h2
{
   color: #ffffff;
}

@media (prefers-reduced-motion: no-preference) {}

.appDebug
{
  background-color: #ffffff;
  margin: 0px auto 10px auto;
  padding: 10px 10px 10px 10px;
  width: calc(100% - 20px);
  overflow-x: scroll;
}

.appHeader
{
  background-color: #ffffff;
  margin: 0px auto 0px auto;
  padding: 10px 10px 10px 10px;
}

.appBody
{
  background-color: #ffffff;
  margin: 0px auto 10px auto;
  padding: 0px 10px 10px 10px;
}

.homeMessage
{
  background-color: #F6F6F6;
  margin: 0px auto 0px auto;
  padding: 30px 10px 10px 10px;
  border: 0px solid black;
  font-size: 0.8em;
  display: none;
}

.homeMessage h1
{
  color: #a7a7a7;
}

.appFooter
{
  background-color: #F6F6F6;
  margin: 0px auto 0px auto;
  padding: 30px 10px 10px 10px;
  border: 0px solid black;
  font-size: 0.8em;
}

.appFooter strong {
  font-size: 1.5em;
}

.appFooter copyright {
  font-size: 1.6em;
}

.appTable
{
  background-color: #ffffff;
  padding: 10px 10px 10px 10px;
  width: calc(100% - 0px);
  overflow-x: scroll;

  -ms-overflow-style: none;
  scrollbar-width: none;
}

.appFilter
{
  background-color: #ffffff;
  padding: 10px 0px 10px 0px;
  width: calc(100%);
  overflow-x: scroll;

  -ms-overflow-style: none;
  scrollbar-width: none;
}

.appFilter::-webkit-scrollbar
{
  display: none;
}

.error, #loginResult
{
  color: #ff0000;
}

#registerResult {
  color: #ff0000;
}

#mapid
{
  height: 180px;
}

.boutline
{
  background-color: #ff0000 !important;
  border: 1px solid black;
}

.displayNone
{
  display: none;
}

.ui-datatable .ui-column-filter {
  width: 100%;
  box-sizing: border-box;
}

.disabled {
  display: none;
}

.displayNone {
  display: none;
}

.p-menubar-root-list {
  text-align: center;
  align-items: center;
}

.p-menubar {
  justify-content: space-between;
}
.p-menubar .p-menubar-end {
  margin-left: 0px !important;
}

.p-toast {
  background: #ffffff;
}

.companyImage{
  background: rgba(255,255,255,0.8);
  padding: 5px 5px 5px 5px;
  border: 1px solid rgba(255,255,255,0.8);
  width: 25%;
  min-width: 300px;
}

.sitemap {
  width: 90%;
  max-width: 1024px;
  margin: 0px auto 0px auto;
  padding: 5px 5px 0px 5px;
  text-align: left;
  overflow-x: scroll;
  border: 0px solid #000000;
  background: #fAfAfA;
}

.pageFrame {
  width: 90%;
  height: 90%;
  border: 0px solid #000000;
  margin: 0px auto 0px auto;
  padding: 5px 5px 5px 5px;
}

.overFlowXScroll {
  overflow-x: scroll;
}
/**/
